<template>
  <div class="satisfaction-form">
    <h3>แบบประเมินความพึงพอใจการตั้งจุดตรวจ</h3>
    <form @submit.prevent="submitForm">
      <div v-for="(question, index) in questions" :key="index" class="question">
        <p>{{ question.text }}</p>
        <div v-for="option in question.options" :key="option.score" class="choice">
          <input
            type="radio"
            :name="`question-${index}`"
            :value="option.score"
            v-model="responses[index]"
            required
          >
          <v-icon v-if="option.score === 4" class="icon-green">
            mdi-emoticon
          </v-icon>
          <v-icon v-if="option.score === 3" class="icon-blue">
            mdi-emoticon-happy
          </v-icon>
          <v-icon v-if="option.score === 2" class="icon-gray">
            mdi-emoticon-neutral
          </v-icon>
          <v-icon v-if="option.score === 1" class="icon-red">
            mdi-emoticon-sad
          </v-icon>
          <label :for="`question-${index}-${option.score}`">{{ option.text }}</label>
        </div>
      </div>
      
      <button type="submit">Submit Survey</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SatisfactionForm',
  data() {
    return {
      questions: [
        {
          text: '1. ที่จุดตรวจ มีแผงกั้นที่มีเครื่องหมายจราจรประเภทป้าย “หยุดตรวจ” มีแผ่นป้าย แสดง ยศ ชื่อ สกุล และตำแหน่งของหัวหน้าจุดตรวจ และข้อความ“หากพบเจ้าหน้าที่ทุจริตหรือประพฤติมิชอบให้แจ้ง ผู้บังคับการ (หมายเลขโทรศัพท์เคลื่อนที่ของผู้บังคับการ) หรือแจ้งร้องเรียนการปฏิบัติหน้าที่ทางสายด่วนหมายเลข 1599” ชัดเจนหรือไม่?',
          options: [
            { text: 'ดีมาก', score: 4 },
            { text: 'ดี', score: 3 },
            { text: 'พอใช้', score: 2 },
            { text: 'ปรับปรุง', score: 1 },
          ]
        },
        {
          text: '2. การปฏิบัติหน้าที่ของตำรวจใช้กิริยา วาจาสุภาพ ระดับใด?',
          options: [
            { text: 'ดีมาก', score: 4 },
            { text: 'ดี', score: 3 },
            { text: 'พอใช้', score: 2 },
            { text: 'ปรับปรุง', score: 1 },
          ]
        },
        {
          text: '3. ท่านพึงพอใจการอำนวยการจราจร ณ จุดตรวจ ระดับใด?',
          options: [
            { text: 'ดีมาก', score: 4 },
            { text: 'ดี', score: 3 },
            { text: 'พอใช้', score: 2 },
            { text: 'ปรับปรุง', score: 1 },
          ]
        },
        {
          text: '4. สถานที่สำหรับตรวจวัดแอลกอฮอล์แบบยืนยันผลในจุดที่มีแสงสว่างเพียงพอและเป็นสถานที่เปิดเผยหรือไม่?',
          options: [
            { text: 'ดีมาก', score: 4 },
            { text: 'ดี', score: 3 },
            { text: 'พอใช้', score: 2 },
            { text: 'ปรับปรุง', score: 1 },
          ]
        },
        {
          text: '5. ตำรวจมีการอธิบายถึงขั้นตอนการตรวจวัดแอลกอฮอล์ให้ท่านทราบ ตั้งแต่เริ่มจนเสร็จสิ้นกระบวนการทั้งหมด หรือไม่?',
          options: [
            { text: 'ดีมาก', score: 4 },
            { text: 'ดี', score: 3 },
            { text: 'พอใช้', score: 2 },
            { text: 'ปรับปรุง', score: 1 },
          ]
        },
      ],
      responses: Array(3).fill('')
    };
  },
  methods: {
    submitForm() {
      const payload = this.questions.map((q, i) => ({
        question: q.text,
        response: this.responses[i]
      }));

      axios.post('http://example.com/api/satisfaction', payload)
        .then(response => {
          alert('Thank you for your feedback!');
          this.responses.fill('');
        })
        .catch(error => {
          console.error('There was an error submitting your form:', error);
          alert('Failed to submit feedback.');
        });
    }
  }
}
</script>

<style>
.satisfaction-form {
  /* max-width: 500px; */
  margin: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
.question {
  margin-top: 20px;
  margin-bottom: 40px;
}
.question p {
  color: darkred;
  font-weight: bold;
  margin-bottom: 10px;
}
.choice {
  margin-bottom: 10px;
  input {
    margin-right: 10px;
  }
}
.icon-green {
  color: lightgreen !important;
}
.icon-blue {
  color: lightblue !important;
}
.icon-gray {
  color: gray !important;
}
.icon-red {
  color: red !important;
}
</style>